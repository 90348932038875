var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-company"},[_c('Banner',[_c('div',{staticClass:"banner-content"},[_c('MeepIconSettings',{staticClass:"banner-content__icon"}),_c('span',{staticClass:"banner-content__text"},[_vm._v(_vm._s(_vm.$t("menu.setting")))])],1)]),_c('PageHeader',{attrs:{"has-actions":true,"has-back":true,"has-search":true,"tabs":_vm.tabs,"title":"Entreprises"},on:{"back":_vm.goBack,"change-tab":_vm.changeTab},scopedSlots:_vm._u([{key:"switch",fn:function(){return [_c('md-switch',{model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}},[_vm._v(_vm._s(_vm.$t("common.showByPage"))+" ")])]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[(_vm.canCreateCompany && _vm.isTabAll)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":function($event){return _vm.$router.push('/dashboard/setting/companies/create')}}},[_c('MeepIconActionAdd',{staticClass:"app-icon-stroke-inverted"})],1):_vm._e(),(_vm.isAdmin && _vm.selectedList.length > 1)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":_vm.onDeleteMultipleCompany}},[_c('MeepIconActionDelete',{staticClass:"app-icon"})],1):_vm._e(),(_vm.isAdmin && _vm.selectedList.length >= 1 && !_vm.isTabAll)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":_vm.onRestoreMultipleCompany}},[_c('MeepIconActionRestore',{staticClass:"app-icon"})],1):_vm._e(),(_vm.isTabAll && !_vm.isClient)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },attrs:{"to":"/dashboard/setting/import"}},[_c('MeepIconActionImport',{staticClass:"app-icon"})],1):_vm._e(),(_vm.isTabAll && !_vm.isClient)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":_vm.exportCompanies}},[_c('MeepIconActionExport',{staticClass:"app-icon"})],1):_vm._e()],1),_c('div',{staticClass:"list-company-content page-layout"},[(_vm.isConfirmModalOpen)?_c('confirm-action-modal',{attrs:{"object-to-act-upon":_vm.selectedList,"text":_vm.confirmModalText},on:{"close":function($event){_vm.isConfirmModalOpen = false},"confirm":_vm.onConfirmModal}}):_vm._e(),(_vm.showAll)?_c('sortable-list',{staticClass:"page-list",attrs:{"has-pagination":_vm.pagination,"items":_vm.filter,"page-size":5,"parameters":_vm.listParameters,"selectable":_vm.isAdmin ? 'multiple' : 'single',"default-sort":"name","link":"/dashboard/setting/companies/view/"},on:{"selected":_vm.onSelect,"item-click":_vm.itemClick}}):_vm._e(),(!_vm.showAll)?_c('sortable-list',{staticClass:"page-list",attrs:{"has-pagination":_vm.pagination,"items":_vm.filter,"page-size":5,"parameters":_vm.trashListParameters,"default-sort":"name","selectable":"multiple"},on:{"selected":_vm.onSelect,"item-click":_vm.itemClick}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }