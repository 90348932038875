<template>
  <div class="list-company">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :has-actions="true"
      :has-back="true"
      :has-search="true"
      :tabs="tabs"
      title="Entreprises"
      @back="goBack"
      @change-tab="changeTab"
    >
      <template #switch>
        <md-switch v-model="pagination">{{
            $t("common.showByPage")
          }}
        </md-switch>
      </template>
      <md-button
        v-if="canCreateCompany && isTabAll"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="$router.push('/dashboard/setting/companies/create')"
      >
        <MeepIconActionAdd class="app-icon-stroke-inverted" />
      </md-button>

      <md-button
        v-if="isAdmin && selectedList.length > 1"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="onDeleteMultipleCompany"
      >
        <MeepIconActionDelete class="app-icon" />
      </md-button>

      <md-button
        v-if="isAdmin && selectedList.length >= 1 && !isTabAll"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="onRestoreMultipleCompany"
      >
        <MeepIconActionRestore class="app-icon" />
      </md-button>

      <md-button
        v-if="isTabAll && !isClient"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        to="/dashboard/setting/import"
      >
        <MeepIconActionImport class="app-icon" />
      </md-button>

      <md-button
        v-if="isTabAll && !isClient"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="exportCompanies"
      >
        <MeepIconActionExport class="app-icon" />
      </md-button>
    </PageHeader>
    <div class="list-company-content page-layout">
      <!-- Modals -->
      <confirm-action-modal
        v-if="isConfirmModalOpen"
        :object-to-act-upon="selectedList"
        :text="confirmModalText"
        @close="isConfirmModalOpen = false"
        @confirm="onConfirmModal"
      />

      <!-- La liste des entreprises -->
      <sortable-list
        v-if="showAll"
        :has-pagination="pagination"
        :items="filter"
        :page-size="5"
        :parameters="listParameters"
        :selectable="isAdmin ? 'multiple' : 'single'"
        class="page-list"
        default-sort="name"
        link="/dashboard/setting/companies/view/"
        @selected="onSelect"
        @item-click="itemClick"
      />

      <!-- La liste des entreprises -->
      <sortable-list
        v-if="!showAll"
        :has-pagination="pagination"
        :items="filter"
        :page-size="5"
        :parameters="trashListParameters"
        class="page-list"
        default-sort="name"
        selectable="multiple"
        @selected="onSelect"
        @item-click="itemClick"
      />
    </div>
  </div>
</template>

<script>
import companiesModel from "../../model/companies";
import vueXlsxTable from "@deevotechvn/vue-xlsx-table";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconActionImport from "@/components/icons/MeepIconActionImport.vue";
import MeepIconActionExport from "@/components/icons/MeepIconActionExport.vue";
import MeepIconActionRestore from "@/components/icons/MeepIconActionRestore.vue";

import Vue from "vue";
import ConfirmActionModal from "../../components/modal/confirm-action";
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import Fuse from "fuse.js";
import { companiesTemplate, downloadExcel } from "@/services/excel";

Vue.use(vueXlsxTable, { rABS: false });

export default {
  name: "CompaniesList",

  components: {
    PageHeader,
    ConfirmActionModal,
    Banner,
    MeepIconSettings,
    MeepIconActionAdd,
    MeepIconActionDelete,
    MeepIconActionImport,
    MeepIconActionExport,
    MeepIconActionRestore,
  },

  data() {
    return {
      currentTab: "tab-all",
      query: "",
      companies: [],
      trashCompanies: [],
      newCompanies: [],
      isConfirmModalOpen: false,
      confirmationContext: "trash",
      selectedList: [],
      pagination: true,

      companyFields: {
        "Nom de l'entreprise": "name",
        "Numéro de SIREN": "siren",
        "Nom du dirigeant": "lastName",
        "Prénom du dirigeant": "firstName",
        "Adresse e-mail": "email",
        "Numéro de téléphone": "tel",
        "Site web": "url",
        Adresse: "adresse",
        Ville: "ville",
        "Code postal": "codepostal",
      },

      trashListParameters: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "SIREN",
          key: "siren",
        },
        {
          name: "Numéro de téléphone",
          key: "tel",
        },
        {
          name: "Groupes",
          key: "groupes",
          format: item => {
            if (!Array.isArray(item)) {
              return "";
            }

            return item.map(group => group.name).join(", ");
          },
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "replay",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
      ],

      showImportCompanyDialog: false,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEP"]),

    currentList() {
      return this.showAll ? this.companies : this.trashCompanies;
    },

    canCreateCompany() {
      return this.isAdmin || this.isCollab;
    },

    isTabAll() {
      return this.currentTab === "tab-all";
    },

    filter() {
      if (this.query === "") {
        return this.currentList;
      }

      const fuse = new Fuse(this.currentList, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["name", "siren", "tel"],
      });

      return fuse.search(this.query).map(item => item.item);
    },

    showAll() {
      return this.currentTab === "tab-all";
    },

    tabs() {
      const defaultTabs = [
        {
          id: "tab-all",
          label: "Tous",
        },
      ];

      if (this.isAdmin) {
        defaultTabs.push({
          id: "tab-trashed",
          label: "Corbeille",
        });
      }

      return defaultTabs;
    },

    listParameters() {
      const defaultParams = [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "SIREN",
          key: "siren",
        },
        {
          name: "Numéro de téléphone",
          key: "tel",
        },
        {
          name: "Groupes",
          key: "groupes",
          format: item => {
            if (!Array.isArray(item)) {
              return "";
            }

            return item.map(group => group.name).join(", ");
          },
        },
      ];

      if (this.isAdmin) {
        defaultParams.push({
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
        });
      }

      return defaultParams;
    },

    confirmModalText() {
      const title =
        this.selectedList.length > 1 ? "des entreprises" : "d'une entreprise";

      switch (this.confirmationContext) {
        case "trash":
          return {
            header: this.$t("list-company.trash-header", { title: title }),

            body: items => {
              if (items.length > 1) {
                return this.$t("list-company.trash-plural", {
                  count: items.length,
                });
              } else
                return this.$t("list-company.trash-single", {
                  fullName: items[0].name,
                });
            },

            question: this.$t("list-company.question"),
          };
        case "delete":
          return {
            header: this.$t("list-company.delete-header", { title: title }),

            body: items => {
              if (items.length > 1) {
                return this.$t("list-company.delete-plural", {
                  count: items.length,
                });
              }

              return this.$t("list-company.delete-single", {
                fullName: items[0].name,
              });
            },

            question: this.$t("list-company.question"),
          };
        case "restore":
          return {
            header: this.$t("list-company.restore-header", { title: title }),
            body: items => {
              if (items.length > 1) {
                return this.$t("list-company.restore-plural", {
                  count: items.length,
                });
              }

              return this.$t("list-company.restore-single", {
                fullName: items[0].name,
              });
            },

            question: this.$t("list-company.question"),
          };
        default:
          return "";
      }
    },
  },

  async mounted() {
    this.companies = await companiesModel.getAll();

    if (this.isAdmin) {
      this.trashCompanies = await companiesModel.getTrashed();
    }
  },

  methods: {
    onDeleteMultipleCompany() {
      this.confirmationContext = this.showAll ? "trash" : "delete";
      this.isConfirmModalOpen = true;
    },

    exportCompanies() {
      try {
        this.isLoading = true;
        downloadExcel(
          this.companies,
          companiesTemplate,
          "EXPORTER DES ENTREPRISES",
          "companies.xlsx",
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err,
        });
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    onRestoreMultipleCompany() {
      this.confirmationContext = "restore";
      this.isConfirmModalOpen = true;
    },

    onSelect(items) {
      this.selectedList = items;
    },

    goBack() {
      window.history.back();
    },

    itemClick({ item, parameter }) {
      if (this.selectedList.length <= 1) {
        this.selectedList = [{ ...item }];
      }
      switch (parameter.iconFormat(item)) {
        case "delete":
          this.confirmationContext = this.showAll ? "trash" : "delete";
          break;
        case "replay":
          this.confirmationContext = "restore";
          break;
      }

      // Open confirmation modal
      this.isConfirmModalOpen = true;
    },

    changeTab(tabId) {
      // Reset select list
      this.selectedList = [];

      this.currentTab = tabId;
    },

    async trashCompany() {
      try {
        await Promise.all(
          this.selectedList.map(async item => {
            await companiesModel.putInTrash(item.id);

            this.$toasted.global.AppSucces({
              message: "L'entreprise '" + item.name + " a bien été supprimée",
            });

            this.companies = this.companies.filter(
              company => item.id !== company.id,
            );

            this.trashCompanies.push(item);
          }),
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async deleteCompany() {
      try {
        await Promise.all(
          this.selectedList.map(async item => {
            await companiesModel.remove(item.id);

            this.trashCompanies = this.trashCompanies.filter(i => {
              return i.id !== item.id;
            });

            this.$toasted.global.AppSucces({
              message:
                "L'entreprise " +
                item.name +
                " a bien été supprimée definitivement",
            });
          }),
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async restoreCompany() {
      try {
        await Promise.all(
          this.selectedList.map(async item => {
            await companiesModel.restore(item.id);

            // Update trash list
            this.trashCompanies = this.trashCompanies.filter(i => {
              return i.id !== item.id;
            });

            this.companies.push(item);

            this.$toasted.global.AppSucces({
              message: "L'entreprise " + item.name + " a bien été restaurée",
            });
          }),
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    onConfirmModal() {
      switch (this.confirmationContext) {
        case "trash":
          this.trashCompany();
          break;
        case "restore":
          this.restoreCompany();
          break;
        case "delete":
          this.deleteCompany();
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .list-company {
    overflow-x: hidden;

    &__download-btn {
      cursor: pointer;

      &:hover {
        background: var(
            --md-theme-default-divider-on-background,
            rgba(0, 0, 0, 0.12)
        );
      }
    }

    .new-companies {
      padding: 5px;

      .header {
        font-weight: bold;
      }

      .md-layout {
        padding: 8px;
      }

      .md-indeterminate {
        margin-top: 10px;
      }
    }

    &-content {
      &.page-layout {
        height: 100%;
      }
    }
  }

  .import-dialog {
    &:first-child {
      padding: 100px 150px;
    }

    &__content {
      width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-weight: bold;
    }

    &__description {
      text-align: center;
    }

    &__icon {
      width: 50px;
      height: 50px;
    }

    &__actions-button {
      margin-right: 20px;
    }
  }
}
</style>
